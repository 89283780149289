;(function ($) {
  var tz = jstz.determine()
  if (tz && tz.name()) {
    $.cookies.set('mstz', tz.name(), {
      domain: document.location.hostname.replace(
        /^(.*\.)?([a-z-]*medshr.+)$/,
        '$2'
      ),
    })
  }
})(window.jQuery)
